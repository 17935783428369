<template>
   <div class="container">
      
      不支持盘前、盘后
      <table class="stockList">
            <thead>
               <tr>
                  <!-- <td>序号</td> -->
                  <td>股票代码</td>
                  <td>股票名称</td>
                  <td>更新时间</td>
                  <td>最新价格</td>
                  <td>今日开盘价</td>
                  <td>今日最高价</td>
                  <td>今日最低价</td>
                  <td>昨日收盘价</td>
                  <td>今日成交量</td>
                  <td>今日成交金额</td>
                  <td>换手率</td>
                  <td>振幅</td>
                  <td>上市日期</td>
                  <td>持仓量</td>
               </tr>
            </thead>
            <tbody>
               <tr v-for="(item,index) in stockList" :key="index">
                  <td>{{item.quote.code}}</td>
                  <td>{{item.stockStaticInfo.name}}</td>
                  <td>{{item.quote.data_time}}</td>
                  <td>{{item.quote.last_price}}</td>
                  <td>{{item.quote.open_price}}</td>
                  <td>{{item.quote.high_price}}</td>
                  <td>{{item.quote.low_price}}</td>
                  <td>{{item.quote.prev_close_price}}</td>
                  <td>{{item.quote.volume | numberAddFinancialUnit}}股</td>
                  <td>{{item.quote.turnover | numberAddFinancialUnit}}</td>
                  <td>{{item.quote.turnover_rate}}%</td>
                  <td>{{item.quote.amplitude}}%</td>
                  <td>{{item.quote.listing_date}}</td>
                  <td>{{item.quote.position}}</td>
               </tr>
            </tbody>
      </table>

   </div>
</template>

<script>
export default {
    data(){
      return {
         stockList:[],
         stockTotal:[],

         webSocket:null,           // websocket对象
         webSocketStatus:"未连接",  // websocket连接状态
         reconnectNumber:0,        // websocket重连次数
      }
   },
   mounted(){
      this.webSocketConnect();
   },
   methods:{
      // .0 建立webSocket连接 -------------------------------------------------------------
      webSocketConnect(){

         let that = this;

         // 官方文档：https://developer.mozilla.org/zh-CN/docs/Web/API/WebSocket
         this.webSocketStatus = "正在连接……";
         if(this.$env === "production"){
            this.webSocket = new WebSocket("ws://118.24.103.166:49151");
         }else{
            this.webSocket = new WebSocket("ws://127.0.0.1:49151");
         }
         
         // 连接成功回调
         this.webSocket.onopen = function(evt) { 
            that.reconnectNumber = 0;
            that.webSocketStatus = "连接成功";
            that.webSocket.send(JSON.stringify({
               token:'123456'
            }));
            that.webSocket.send(JSON.stringify({
               operationType:'realtimeDataQuote'
            }));
         };
         
         // 收到服务器数据回调
         this.webSocket.onmessage = function(evt) {
            try {
               let data = JSON.parse(evt.data);
               that.stockTotal = data.stockTotal;

               that.stockList = [];
               data.stockList.forEach((ele)=>{
                  that.stockList.push({
                     'stockDynamicInfo':JSON.parse(ele.stockDynamicInfo),
                     'stockStaticInfo':JSON.parse(ele.stockStaticInfo),
                     'quote':ele.quote
                  });
               });
            } catch(e) {
               console.log(evt.data);
            }            
         };
         
         // 连接关闭后回调
         this.webSocket.onclose = function(evt) {
            that.webSocketStatus = "连接关闭";
         };    
         // 连接出错后回调
         this.webSocket.onerror = function(evt) {
            that.webSocket.close();
            that.webSocketStatus = "连接失败";
            that.webSocketReconnect(); // 连接失败自动重连
         };    
      },
      
      // 2.1 webSocket重新连接 -------------------------------------------------------------
      webSocketReconnect(){
         ++this.reconnectNumber;
         this.webSocket = null;
         this.webSocketConnect();
      }
   },
   // Vue生命周期：页面离开之前被调用
   beforeDestroy: function () {
      this.webSocket.close();
      this.webSocket = null;
   }
   
}
</script>

<style scoped>

.container{
   display:flex;
   flex-direction:column;
}


table.stockList{
   width: 100%;
   height: 100%;
}
table.stockList tbody {
   display:block;
   overflow:auto;
   height: calc(100% - 26px);
}
table.stockList thead tr{
   font-weight: bold;
}
table.stockList thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed; /* even columns width , fix width of table too*/
}
table.stockList tbody tr:hover{
   background-color: rgb(245, 245, 245);
}
table.stockList tr>td:nth-child(1){
   width:80px;
   text-align:center;
}

</style>